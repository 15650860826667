<template>
  <div class="bg-white user-create-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="user_modal_delete"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteUser()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <form class="form-horizontal" v-on:submit.prevent="passes(createOrUpdate)">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">

              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-1 col-sm-1 m-t-8">{{ entry.id }}</label>
                  <label class="col-xl-5 col-lg-6 col-sm-6 m-t-8">
                    <router-link
                      :to="{ name: ROUTES.ADMIN.ACCOUNT_EDIT_PASSWORD, params: {id:idEditing} }"
                      style="text-decoration: underline"
                    >{{ $t("users.change_password") }}
                    </router-link>
                  </label>
                </div>
              </template>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <app-input
                    name="name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.email") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-6 col-sm-6">
                  <app-input
                    name="email"
                    type="string128"
                    rules="required|email"
                    v-model="entry.email"
                  />
                </div>
              </div>
              <div v-if="!idEditing" class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
                <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass m-b-1">{{ $t("users.create_email_note") }}</label>
              </div>
              <template v-if="idEditing">
                <div class="form-group row m-b-1">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
                  <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">{{ $t("users.edit_note1") }}</label>
                </div>
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
                  <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">{{ $t("users.edit_note2") }}</label>
                </div>
              </template>
              <template v-else>
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.password") }} <span class="app-require">＊</span></label>
                  <div class="col-xl-5 col-lg-6 col-sm-6">
                    <app-input
                      name="password"
                      disabled="true"
                      placeholder="********"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label p-0"></label>
                  <label class="col-xl-5 col-lg-6 col-sm-6 notifi-resetpass">{{ $t("users.create_password_note") }}</label>
                </div>
              </template>
              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDeleteUser">
                  {{ $t('common.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" type="submit">
                  {{  $t('common.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary" type="submit">
                  {{  $t('common.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { createHmac } from 'crypto';
import {ROUTES} from "@constants";
import {mapState} from "vuex";
export default {
  name: "FormPage",

  components: {
  },

  data() {
    const id = this.$route.params.id
    return {
      testInput: "",
      testSelect: "",
      radio: {
        block: "opton 1",
        inline: "",
        right: "",
      },
      checkbox: {
        block: ["opton 1", "option 2"],
        inline: [],
        circle: [],
        right: [],
      },
      entry: {
        role: 1,
        status_enabled:1,
      },
      apiKeyId: null,
      meta: {
        status:[]
      },
      idEditing: id,
      entryNameUpdate: "",
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.currentAuth.user,
    })
  },
  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_ACCOUNTS).then(res => {
      this.meta = {
        apartments: res.data.apartments,
        status: res.data.status,
      }

    })

    if (this.idEditing) {
      this.$request.get(this.ENDPOINT.USERS_EDIT(this.idEditing)).then(res => {
        if (res.hasErrors()) {
          this.redirectDefaultValue()
        }
        this.entry = res.data.data
        this.entryNameUpdate = this.entry.name;
      })
    }
  },

  methods: {
    handleTestChange(e) {
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.ALL_USERS_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.USERS_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.USER_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        console.log('vaoday');
        const user = this.$store.getters["auth/getUser"]
        if (this.idEditing && this.idEditing == user.id) {
          user.name = this.entry.name;
          this.$store.commit(`auth/updateAuth`, {
            user: this.entry,
          });
          $(".info").find("#name_user_login").text(this.entry.name)
        }
        if (!(this.idEditing && this.idEditing == user.id && this.entry.status_enabled == '0')) {
          this.__noticeSuccess(msg);
        }
        await this.redirectDefaultValue();
      }
    },
    async redirectDefaultValue() {
     await this.$router.push({name: this.ROUTES.ADMIN.ALL_USERS_LIST,query: {
          'filters.apartment_id.equal' : 'all',
          'filters.status_enabled.equal' : 'all',
        }});
    },
    handleDeleteUser() {
      this.$refs.user_modal_delete.show();
    },
    async deleteUser() {
      const res = await this.$request.delete(this.ENDPOINT.USERS_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.user_modal_delete.hide();
      }
    },
  },
};
</script>
